<template>
  <div class="users view">
    <app-header :title="__('view.auth.users.title')"/>
    <progress-linear v-if="loading" :indeterminate="true"/>
    <div class="control-bar" :class="{'control-bar--disabled': loading}">
      <div class="control-bar__info">
        <div class="control-bar__info-title">{{ __('common.total') }}:</div>
        <div class="control-bar__info-value">{{ pagination.total }}</div>
      </div>
      <text-field
          class="control-bar__search"
          v-model="searchTerm"
          :label="__('common.search_term')"
          max-length="50"
          :hide-details="true"
          :clearable="true"
          @keyup:enter="refreshDelayed(100)"
          @click:clear="refreshDelayed(100)"
      />
      <div class="control-bar__buttons">
        <div class="control-bar__button" :title="__('common.refresh')" @click="refresh" v-slashes>
          <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/refresh.svg"
               alt="refresh"/>
        </div>
      </div>
    </div>
    <div class="scroll-horizontal-overflow">
      <pagination
          class="mt"
          :count-pages="pagination.pages"
          :total-visible="10"
          :page="pagination.page"
          :disabled="loading"
          @paginate="paginate"
      />
      <data-table
          :records="users"
          :fields="dataTableFields"
          :record-actions="recordActions"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          :loading="loading"
          @disableUser="disableUser"
          @enableUser="enableUser"
          @editUser="editUser"
          @resendConfirmationEmail="resendConfirmationEmail"
          @sort="sort"
          @syncGitlabAccess="syncGitlabAccess"
      />
      <pagination
          class="mt"
          :count-pages="pagination.pages"
          :total-visible="10"
          :page="pagination.page"
          :disabled="loading"
          @paginate="paginate"
      />
    </div>
    <edit-user ref="editUser" @userUpdated="refresh"/>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import apiSecured from '../../api/secured';
import DataTable from '../../components/DataTable'
import AppHeader from '../../components/AppHeader'
import EditUser from '../../components/Auth/EditUser'
import { notificationsMixin } from '../../mixins/notifications';
import { localizationMixin } from '../../mixins/localization';
import { paginationMixin } from "../../mixins/pagination";
import { pageTitleMixin } from "../../mixins/pageTitle";

export default {
  mixins: [notificationsMixin, localizationMixin, paginationMixin, pageTitleMixin],
  components: {
    appHeader: AppHeader,
    dataTable: DataTable,
    editUser: EditUser
  },
  watch: {
    searchTerm() {
      this.pagination.page = 1;
    }
  },
  data: () => ({
    loading: false,
    users: [],
    dataTableFields: [],
    recordActions: [],
    userToEdit: null,
  }),
  methods: {
    refreshDelayed(delay) {
      setTimeout(this.refresh, delay);
    },
    refresh() {
      this.loading = true;
      apiSecured.get('/identity/user', {
        params: this.getPaginationParams()
      }).then((res) => {

        this.users = res.data;
        this.updatePagination(res);
        this.loading = false;
        this.showInfoNotification('Users have been reloaded');
      }).catch((error) => {
        this.loading = false;
        this.showErrorNotifications(error)
      });
    },
    disableUser(user) {
      Swal.fire({
        title: 'Are you sure?',
        text: "Do you really want to disable this user and prevent him from logging in?",
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, disable him!'
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          apiSecured.patch(`/identity/user/${user.id}/lock-unlock`, {
            lockUser: true
          }).then((res) => {
            this.loading = false;
            this.showSuccessNotification('User has been disabled');
            this.refresh()
          }).catch((error) => {
            this.loading = false;
            this.showErrorNotifications(error);
            this.refresh()
          });
        }
      });
    },
    enableUser(user) {
      Swal.fire({
        title: 'Are you sure?',
        text: "Do you really want to enable this user and allow him to log in?",
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, enable him!'
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          apiSecured.patch(`/identity/user/${user.id}/lock-unlock`, {
            lockUser: false
          }).then((res) => {
            this.loading = false;
            this.showSuccessNotification('User has been enabled');
            this.refresh()
          }).catch((error) => {
            this.loading = false;
            this.showErrorNotifications(error);
            this.refresh()
          });
        }
      });
    },
    resendConfirmationEmail(user) {
      Swal.fire({
        title: 'Are you sure?',
        text: "Do you really want to regenerate the confirmation token and send it per email to the user?",
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, send it!'
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          apiSecured.post(`/identity/user/${user.id}/resend-confirmation-email`, {}).then((res) => {
            this.loading = false;
            this.showSuccessNotification('Email has been sent');
            this.refresh()
          }).catch((error) => {
            this.loading = false;
            this.showErrorNotifications(error);
            this.refresh()
          });
        }
      });
    },
    syncGitlabAccess(user) {
      this.loading = true;
      apiSecured.patch(`/identity/user/fix-gitlab-access/${user.id}`, {}).then((res) => {
        this.loading = false;
        this.showSuccessNotification('Gitlab Access synchronized');
        this.refresh()
      }).catch((error) => {
        this.loading = false;
        this.showErrorNotifications(error);
        this.refresh()
      });
    },
    editUser(user) {
      this.$refs.editUser.showEdit(user);
    },
  },
  created() {
    this.setPageTitle(this.__('entity.common.users'));
    this.dataTableFields = [
      { name: "id", title: this.__('entity.common.id'), type: "default" },
      { name: "userName", title: this.__('entity.auth.user.username'), type: "default" },
      { name: "email", title: this.__('entity.auth.user.email'), type: "default" },
      { name: "emailConfirmed", title: this.__('common.confirmed'), type: "boolean" },
      { name: "lockoutEnabled", title: this.__('entity.auth.user.enabled'), type: "boolean-negated" },
      { name: "roleNames", title: this.__('entity.common.roles'), type: "array", disableSorting: true },
      { name: "lastLogin", title: this.__('entity.auth.user.last_login'), type: "datetime" },
      { name: "createdAt", title: this.__('entity.common.created_at'), type: "datetime" },
      { name: "updatedAt", title: this.__('entity.common.updated_at'), type: "datetime" }
    ];
    this.recordActions = [
      { title: this.__('component.auth.edit_user.title'), icon: 'pencil', event: 'editUser' },
      {
        title: this.__('view.auth.users.resend_confirmation_email'),
        icon: 'email',
        event: 'resendConfirmationEmail'
      },
      {
        title: 'Sync Gitlab Access',
        icon: 'gitlab',
        event: 'syncGitlabAccess'
      },
      {
        title: this.__('view.auth.users.enable_user'), icon: 'switch-off', event: 'enableUser', vif: (record) => {
          return record.lockoutEnabled
        }
      },
      {
        title: this.__('view.auth.users.disable_user'), icon: 'switch-on', event: 'disableUser', vif: (record) => {
          return !record.lockoutEnabled
        }
      }
    ];
    this.refresh();
  }
}
</script>
