<template>
  <div class="edit-user">
    <my-dialog :show="show" @close="close">
      <div class="card">
        <div class="card__header">
          <div class="card__title">{{__('component.auth.edit_user.title')}}</div>
          <div class="card__button" v-slashes @click="close"><img
              src="https://cdn.hyperion-corporation.de/ui/svgs/cross.svg" alt="x"/></div>
        </div>
        <div class="card__content">
          <progress-linear v-if="loading"/>
          <div class="layout layout--wrap" v-if="originalUser">
            <div class="flex xs12 sm6">
              <table class="object-table">
                <tr>
                  <td>{{__('entity.common.id')}}:</td>
                  <td>{{ originalUser.id}}</td>
                </tr>
                <tr>
                  <td>{{__('entity.auth.user.username')}}:</td>
                  <td>{{ originalUser.userName }}</td>
                </tr>
                <tr>
                  <td>{{__('entity.auth.user.email')}}:</td>
                  <td>{{ originalUser.email }}</td>
                </tr>
                <tr>
                  <td>{{__('entity.auth.user.email_confirmed')}}:</td>
                  <td>{{ originalUser.emailConfirmed }}</td>
                </tr>
                <tr>
                  <td>{{__('entity.auth.user.enabled')}}:</td>
                  <td>{{ !originalUser.lockoutEnabled }}</td>
                </tr>
                <tr>
                  <td>{{__('entity.auth.user.roles')}}:</td>
                  <td>{{ originalUser.roleNames.join(', ') }}</td>
                </tr>
                <tr>
                  <td>{{__('entity.auth.user.last_login')}}:</td>
                  <td>{{ originalUser.lastLogin }}</td>
                </tr>
                <tr>
                  <td>{{__('entity.common.created_at')}}</td>
                  <td>{{ originalUser.createdAt }}</td>
                </tr>
                <tr>
                  <td>{{__('entity.common.updated_at')}}:</td>
                  <td>{{ originalUser.updatedAt }}</td>
                </tr>
                <tr>
                  <td>Discord Id:</td>
                  <td>{{ originalUser.discordId }}</td>
                </tr>
              </table>
            </div>
            <div class="flex xs12 sm6 mt">
              <div class="layout layout--wrap">
                <div class="flex xs12">
                  <text-field
                          v-model="user.userName"
                          :label="__('entity.auth.user.username')"
                          :error-messages="errors.userName"
                          maxlength="50"
                          :prepend-icon="isChanged('userName') ? 'pencil' : null"
                          @input="registerChange('userName')"
                  />
                </div>
                <div class="flex xs12">
                  <text-field
                          v-model="user.email"
                          :label="__('entity.auth.user.email')"
                          :error-messages="errors.email"
                          type="email"
                          maxlength="50"
                          :prepend-icon="isChanged('email') ? 'pencil' : null"
                          @input="registerChange('email')"
                  />
                </div>
                <div class="flex xs12">
                  <text-field
                      v-model="user.avatar"
                      :label="__('component.auth.edit_myself.avatar_url')"
                      :error-messages="errors.avatar"
                      maxlength="255"
                      :prepend-icon="isChanged('avatar') ? 'pencil' : null"
                      @input="registerChange('avatar')"
                  />
                </div>
              </div>
            </div>
            <div class="flex xs6 mt">
            </div>
            <div class="flex xs6 mt">
              <div class="btn btn--success" :loading="loading" :disabled="changedProperties.length === 0"
                   @click="updateUser" v-slashes>{{ __('common.save_changes') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </my-dialog>
  </div>
</template>

<script>
  import deepcopy from 'deepcopy';
  import isEmail from 'validator/lib/isEmail';
  import apiSecured from '../../api/secured';
  import {notificationsMixin} from '../../mixins/notifications';
  import {localizationMixin} from '../../mixins/localization';

  export default {
    mixins: [notificationsMixin, localizationMixin],
    data: () => ({
      originalUser: null,
      loading: false,
      user: null,
      show: false,
      changedProperties: [],
      errors: {
        userName: [],
        email: [],
        avatar: [],
      }
    }),
    methods: {
      showEdit({id}) {
        apiSecured.get('/identity/user/' + id).then((res) => {
          this.originalUser = res.data;
          this.loading = false;
          this.showInfoNotification(this.__('common.data_loaded'));
          this.prepare();
          this.show = true;
        }).catch((error) => {
          this.originalUser = null;
          this.loading = false;
          this.show = false;
          this.prepare();
          this.showErrorNotifications(error)
        });
      },
      prepare() {
        this.user = deepcopy(this.originalUser);
        this.changedProperties = [];
      },
      registerChange(property) {
        if (!this.changedProperties.includes(property)) {
          this.changedProperties.push(property);
        }
      },
      isChanged(property) {
        return this.changedProperties.includes(property);
      },
      close() {
        this.show = false;
        this.$emit('closed')
      },
      updateUser() {
        let payload = [];
        let isValid = true;
        this.errors = {
          userName: [],
          email: [],
        };
        this.changedProperties.forEach((changedProperty) => {
          payload.push({
            op: 'replace',
            path: `/${changedProperty}`,
            value: this.user[changedProperty]
          });

          if (changedProperty === 'userName') {
            if (this.user[changedProperty].length < process.env.VUE_APP_CONSTRAINT_USERNAME_MINLENGTH) {
              this.errors.userName.push(this.__('validation.too_short').replace('$1', changedProperty).replace('$2', process.env.VUE_APP_CONSTRAINT_USERNAME_MINLENGTH));
              isValid = false;
            }
          } else if (changedProperty === 'email') {
            if (this.user[changedProperty].length < process.env.VUE_APP_CONSTRAINT_USERNAME_MINLENGTH || !isEmail(this.user[changedProperty])) {
              this.errors.email.push(this.__('component.auth.edit_user.email_not_valid'));
              isValid = false;
            }
          }
        });

        if (!isValid) {
          return;
        }

        this.loading = true;
        apiSecured.patch('/identity/user/' + this.user.id, payload).then((res) => {
          this.loading = false;
          this.showSuccessNotification(this.__('common.saved'));
          this.originalUser = res.data;
          this.prepare();
          this.changedProperties = [];
          this.$emit('userUpdated');
        }).catch((error) => {
          this.loading = false;
          this.showErrorNotifications(error);
        });
      }
    },
    created() {
      this.prepare()
    }
  }
</script>

<style scoped>

</style>